<template>
  <div class="page--permissions">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="4" xl="3">
          <v-card rounded="lg" :loading="isSearchingUser">
            <v-col>
              <v-row>
                <v-col class="py-7">
                  <v-text-field
                    v-if="$vuetify.breakpoint.mdAndUp"
                    ref="userSearch"
                    v-model="nameToSearch"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Pesquisar usuário"
                    autocomplete="off"
                    hide-details
                    clearable
                    solo
                    rounded
                    @keyup="fetchUser()"
                  ></v-text-field>
                  <v-autocomplete
                    v-else
                    ref="userAutoSearch"
                    v-model="userNameAutoSearch"
                    :search-input.sync="nameToSearch"
                    :items="searchedUsers"
                    item-text="name"
                    clearable
                    solo
                    rounded
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    autocomplete="off"
                    no-data-text="Nenhum usuário encontrado"
                    placeholder="Pesquisar usuário"
                    @keyup="fetchUser()"
                    @change="onUserAutoSearchSelect()"
                  >
                    <template #item="data">
                      <template>
                        <v-list-item-avatar>
                          <img alt="Foto do usuário" :src="data.item.picture" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.email"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-divider class="d-none d-md-flex"></v-divider>

              <v-row class="d-none d-md-flex">
                <v-col>
                  <v-responsive>
                    <v-list two-line max-height="1000" class="overflow-auto">
                      <v-list-item
                        v-for="(user, ui) in searchedUsers"
                        :key="ui"
                      >
                        <v-list-item-avatar>
                          <v-img :src="user.picture"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="user.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="user.email"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn text rounded @click="selectUser(user)">
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" md="7" lg="8" xl="9">
          <v-card rounded="lg" class="pa-5">
            <v-card v-if="selectedUser" elevation="0" class="my-5">
              <div class="text-center">
                <v-avatar class="" size="80">
                  <v-img :src="selectedUser.picture" class="thumbnail"></v-img>
                </v-avatar>
                <br />
                <span
                  v-text="selectedUser.name"
                  class="d-inline-block mt-1 font-weight-medium"
                ></span>
              </div>
            </v-card>

            <v-card
              :class="{
                'mb-1 mb-lg-6': selectedUser.permissions.length - 1 !== pi,
              }"
              rounded="lg"
              v-for="(permission, pi) in selectedUser.permissions"
              :key="`${permission.title}-${pi}`"
              elevation="1"
            >
              <v-list subheader two-line>
                <v-subheader inset class="ml-0">
                  <v-icon
                    class="mr-4"
                    v-if="permission.icon"
                    v-text="permission.icon"
                  ></v-icon>
                  <span v-text="permission.title" class="break-spaces"></span>
                </v-subheader>

                <v-list-item
                  :disabled="!selectedUser"
                  v-for="(item, ii) in permission.items"
                  :key="`${item.title}-${ii}`"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        class="mr-3"
                        v-if="item.icon"
                        v-text="item.icon"
                      ></v-icon>
                      <span v-text="item.title" class="break-spaces"></span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.subtitle"
                      class="break-spaces"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="item.value"
                      :loading="item.isSaving"
                      inset
                      @change="savePermissionChange(item, item.value)"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";

export default {
  name: "Permissions",
  mixins: [pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/"
      },
      {
        text: "Exemplos",
        to: "/exemplos/listar"
      },
      {
        text: "Exemplo de Permissões",
        to: "/exemplos/permissoes"
      }
    ],
    users: [
      {
        id: 1,
        picture: "https://randomuser.me/api/portraits/men/20.jpg",
        name: "Troy Bennett",
        email: "troy.bennett@example.com",
        address: "5134 Valley View Ln",
        phone: "(169)-634-7118",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: true },
          { id: 13, value: true },
          { id: 14, value: true },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: true },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 2,
        picture: "https://randomuser.me/api/portraits/men/37.jpg",
        name: "Corey Prescott",
        email: "corey.prescott@example.com",
        address: "7891 Timber Wolf Trail",
        phone: "(173)-809-0023",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: false },
          { id: 13, value: true },
          { id: 14, value: false },
          { id: 21, value: true },
          { id: 22, value: false },
          { id: 23, value: false },
          { id: 31, value: true },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 3,
        picture: "https://randomuser.me/api/portraits/women/48.jpg",
        name: "Priscilla Caldwell",
        email: "priscilla.caldwell@example.com",
        address: "6891 Miller Ave",
        phone: "(476)-428-3393",
        status: "inactive",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: false },
          { id: 13, value: true },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: true },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 4,
        picture: "https://randomuser.me/api/portraits/men/85.jpg",
        name: "Jorge Bennett",
        email: "jorge.bennett@example.com",
        address: "6467 Avondale Ave",
        phone: "(880)-470-6705",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: true },
          { id: 13, value: true },
          { id: 14, value: true },
          { id: 21, value: true },
          { id: 22, value: false },
          { id: 23, value: true },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 5,
        picture: "https://randomuser.me/api/portraits/men/42.jpg",
        name: "David Silva",
        email: "david.silva@example.com",
        address: "5033 E North St",
        phone: "(982)-631-3810",
        status: "blocked",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: true },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: true },
          { id: 22, value: false },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 6,
        picture: "https://randomuser.me/api/portraits/women/4.jpg",
        name: "Loretta Davidson",
        email: "loretta.davidson@example.com",
        address: "4514 Brown Terrace",
        phone: "(304)-526-7444",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: true },
          { id: 13, value: true },
          { id: 14, value: true },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: true },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      },
      {
        id: 7,
        picture: "https://randomuser.me/api/portraits/women/15.jpg",
        name: "Annie Morgan",
        email: "annie.morgan@example.com",
        address: "3635 Lovers Ln",
        phone: "(871)-719-3077",
        status: "inactive",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: false },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: false },
          { id: 31, value: true },
          { id: 32, value: true },
          { id: 33, value: true }
        ]
      },
      {
        id: 8,
        picture: "https://randomuser.me/api/portraits/men/51.jpg",
        name: "Felix Day",
        email: "felix.day@example.com",
        address: "922 White Oak Dr",
        phone: "(784)-860-3180",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: false },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: true }
        ]
      },
      {
        id: 9,
        picture: "https://randomuser.me/api/portraits/women/43.jpg",
        name: "Jessie Armstrong",
        email: "jessie.armstrong@example.com",
        address: "2931 Brown Terrace",
        phone: "(038)-671-6830",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: true },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: true }
        ]
      },
      {
        id: 10,
        picture: "https://randomuser.me/api/portraits/men/59.jpg",
        name: "Miguel Evans",
        email: "miguel.evans@example.com",
        address: "3663 Hamilton Ave",
        phone: "(905)-842-5272",
        status: "active",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: false },
          { id: 13, value: true },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: true },
          { id: 32, value: true },
          { id: 33, value: false }
        ]
      },
      {
        id: 11,
        picture: "https://randomuser.me/api/portraits/women/93.jpg",
        name: "Riley Peck",
        email: "riley.peck@example.com",
        address: "5505 Avondale Ave",
        phone: "(055)-498-8037",
        status: "active",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: true },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: true },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: true },
          { id: 33, value: false }
        ]
      },
      {
        id: 12,
        picture: "https://randomuser.me/api/portraits/men/15.jpg",
        name: "Miguel Ferguson",
        email: "miguel.ferguson@example.com",
        address: "6433 Oak Lawn Ave",
        phone: "(312)-303-8971",
        status: "active",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: false },
          { id: 13, value: true },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: false },
          { id: 33, value: true }
        ]
      },
      {
        id: 13,
        picture: "https://randomuser.me/api/portraits/women/2.jpg",
        name: "Christina Martin",
        email: "christina.martin@example.com",
        address: "7118 Oak Ridge Ln",
        phone: "(229)-875-6444",
        status: "active",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: false },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: false },
          { id: 22, value: false },
          { id: 23, value: false },
          { id: 31, value: true },
          { id: 32, value: false },
          { id: 33, value: true }
        ]
      },
      {
        id: 14,
        picture: "https://randomuser.me/api/portraits/women/42.jpg",
        name: "Sheila Palmer",
        email: "sheila.palmer@example.com",
        address: "6321 Northaven Rd",
        phone: "(066)-802-3830",
        status: "active",
        permissions: [
          { id: 11, value: false },
          { id: 12, value: true },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: true },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: false },
          { id: 32, value: true },
          { id: 33, value: false }
        ]
      },
      {
        id: 15,
        picture: "https://randomuser.me/api/portraits/men/91.jpg",
        name: "Robert Curtis",
        email: "robert.curtis@example.com",
        address: "9934 Karen Dr",
        phone: "(240)-086-1968",
        status: "active",
        permissions: [
          { id: 11, value: true },
          { id: 12, value: false },
          { id: 13, value: false },
          { id: 14, value: false },
          { id: 21, value: true },
          { id: 22, value: true },
          { id: 23, value: false },
          { id: 31, value: true },
          { id: 32, value: false },
          { id: 33, value: false }
        ]
      }
    ],
    selectedUserId: undefined,
    nameToSearch: undefined,
    userNameAutoSearch: undefined,
    isSearchingUser: false,
    permissions: [
      {
        id: 1,
        title: "Aplicativo",
        subtitle: undefined,
        icon: "mdi-tune",
        items: [
          {
            id: 11,
            title: "Email",
            icon: undefined,
            subtitle: "Envia e-mail aos usuários",
            value: false,
            isSaving: false
          },
          {
            id: 12,
            title: "Tema",
            icon: undefined,
            subtitle: "Altera o tema do sistema",
            value: false,
            isSaving: false
          },
          {
            id: 13,
            title: "Idioma",
            icon: undefined,
            subtitle: "Altera o idioma do sistema",
            value: false,
            isSaving: false
          },
          {
            id: 14,
            title: "Fuso horário",
            icon: undefined,
            subtitle: "Altera o fuso horário do sistema",
            value: false,
            isSaving: false
          }
        ]
      },
      {
        id: 2,
        title: "Administração",
        subtitle: undefined,
        icon: "mdi-account-cog",
        items: [
          {
            id: 21,
            title: "Cadastrar usuário",
            icon: undefined,
            subtitle: "Cria e atualiza os usuários do sistema.",
            value: false,
            isSaving: false
          },
          {
            id: 22,
            title: "Remover usuário",
            icon: undefined,
            subtitle:
              "Para remover usuário, se tem acesso a listagem de usuários",
            value: false,
            isSaving: false
          },
          {
            id: 23,
            title: "Convidar usuário",
            icon: undefined,
            subtitle: undefined,
            value: false,
            isSaving: false
          }
        ]
      },
      {
        id: 3,
        title: "Relatórios",
        subtitle: undefined,
        icon: "mdi-finance",
        items: [
          {
            id: 31,
            title: "Fornecedores",
            icon: undefined,
            subtitle: undefined,
            value: false,
            isSaving: false
          },
          {
            id: 32,
            title: "Gerências regionais",
            icon: undefined,
            subtitle: undefined,
            value: false,
            isSaving: false
          },
          {
            id: 33,
            title: "Professores",
            icon: undefined,
            subtitle: undefined,
            value: false,
            isSaving: false
          },
          {
            id: 34,
            title: "Alunos",
            icon: undefined,
            subtitle: undefined,
            value: false,
            isSaving: false
          }
        ]
      }
    ],
    interval: undefined,
  }),
  computed: {
    /**
     * Retorna o usuário selecionado.
     * Por padrão retorna o primeiro usuário da listagem
     */
    selectedUser() {
      return this.selectedUserId
        ? this.users.find(u => this.selectedUserId === u.id)
        : this.users[0];
    },
    /**
     * Usuários filtrados.
     * Usado na listagem dinâmica da pesquisa de usuários
     */
    searchedUsers() {
      const minCharacters = 3;

      if (!this.nameToSearch) {
        return this.users;
      }
      const searchedName = this.nameToSearch
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      if (!searchedName || searchedName.length < minCharacters) {
        return this.users;
      } else {
        return this.users.filter(
          user =>
            user.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(searchedName) >= 0
        );
      }
    }
  },
  mounted() {
    this.transformUsers();
    this.hidePageLoading();
  },
  methods: {
    /**
     * Chamado quando um item do autocomplete (apenas mobile) é selecionado ou limpo
     */
    onUserAutoSearchSelect() {
      if(this.userNameAutoSearch) {
        this.selectedUserId = this.users.find(u => u.name === this.userNameAutoSearch).id;
        this.$refs.userAutoSearch.clearableCallback(true);
      }
    },
    /**
     * Salva na API a alteração da permissão
     */
    savePermissionChange(permission, newValue) {
      permission.isSaving = true;

      // Simulando tempo de requisição à API.
      // Deve-se remover o timeout em implementação real
      setTimeout(() => {
        permission.value = newValue;
        permission.isSaving = false;
      }, 1000);
    },
    /**
     * Consulta o usuário na API após o usuário do sistema digitar mais de 2 caracteres na pesquisa
     */
    fetchUser() {
      const minCharacters = 3;
      const delayToSearch = 500;
      const searchedName = (this.nameToSearch || '')
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      this.isSearchingUser = false;
      clearTimeout(this.interval);

      if (searchedName.length >= minCharacters) {
        this.isSearchingUser = true;
        this.interval = setTimeout(() => {
          // efetua aqui a pesquisa
          // adiciona à lista se não existir
          if (!this.users.find(u => u.id === 100)) {
            let pauloFreire = {
              id: 100,
              picture: "/img/paulo_freire.jpg",
              name: "Paulo Freire",
              email: "paulo.freire@example.com",
              address: "5134 Valley View Ln",
              phone: "(169)-634-7118",
              status: "active",
              permissions: [
                { id: 11, value: true },
                { id: 12, value: true },
                { id: 13, value: true },
                { id: 14, value: true },
                { id: 21, value: true },
                { id: 22, value: true },
                { id: 23, value: true },
                { id: 31, value: true },
                { id: 32, value: true },
                { id: 33, value: true },
                { id: 34, value: true }
              ]
            };

            pauloFreire.permissions = this.getTransformedUserPermissions(
              pauloFreire
            );
            this.users.push(pauloFreire);
          }

          this.isSearchingUser = false;
        }, delayToSearch);
      }
    },
    /**
     * Seleciona o usuário para alteração
     */
    selectUser(user) {
      this.selectedUserId = user.id;
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.$refs.userSearch.clearableCallback();
      }
    },
    /**
     * Transforma (mescla) as permissões do usuário com base na lista principal de permissões
     */
    getTransformedUserPermissions(user) {
      return this.permissions.map(p => {
        return {
          ...p,
          items: p.items.map(i => {
            return {
              ...i,
              value: (
                user.permissions.find(up => up.id === i.id) || { value: false }
              ).value
            };
          })
        };
      });
    },
    /**
     * Transforma as permissões de todos os usuários para permitir a correta renderização da página
     */
    transformUsers() {
      this.users = this.users.map(user => {
        user.permissions = this.getTransformedUserPermissions(user);
        return user;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.thumbnail {
  border: 1px solid #ddd;
  border-radius: 10cm;

  .v-image__image--cover {
    border: 5px solid #fff;
    border-radius: 10cm;
  }
}
</style>